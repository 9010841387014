
    import { Component, Vue, Watch } from "vue-property-decorator"

    import CourseTest from './test/index.vue'
    import CourseDetail from './detail/index.vue'
    import MineCurriculum from './mineCurriculum/index.vue'

    import { mapMutations, mapActions } from 'vuex'

    import { Caxios } from './../../../utils/axios'

    @Component({
        name: 'Course',
        components: {
            CourseTest,
            CourseDetail,
            MineCurriculum
        },
        methods: {
            ...mapMutations('course', ['setSubjectLevel']),
            ...mapActions('course', [
                'setCurriculumObj',
                'setHotCourseList',
                'setTeachersList',
                'setSelectOptionsStable'
            ])
        },
    })
    export default class MineDetail extends Vue {
        setCurriculumObj!: any
        setHotCourseList!: any
        setTeachersList!: any
        setSelectOptionsStable!: any
        setSubjectLevel!: any

        private id: any = "";
        private branchSchoolId: number = -1
        private curItemSubject: string = ''
        // private subjectLevel: string = ''

        private courseid: string = ''

        @Watch("$route", { immediate: true, deep: true })
        routeWatch(newVal: any): void {
            this.id = newVal.query.courseid || null

            this.branchSchoolId = Number(localStorage.getItem("fangtian_branchSchoolId"));
            if(this.id != 0) {
                this.getCurriculumObj(this.id);
            }
            this.getCurriculumObj();
            this.getSelectOptionsStable(this.id);
        }

        private async getTeachersList(ids?: string) {
            let _result = await Caxios.get({ url: `/api/official/teachers?ids=${ids}` });
            this.setTeachersList(_result);
        }

        private async getCurriculumObj(id?: number) {
            let _url: any = id
                ? `/api/rightcourses/${id}?course_id=${id}`
                : `/api/rightcourses?limit=3&page=1&branch_school_id=${this.branchSchoolId}`,
            _result: any = await Caxios.get({ url: _url });
            if (id) {
                this.curItemSubject = _result.subject
                this.setCurriculumObj(_result);
                this.getTeachersList(_result.teacher_ids);
                this.courseid = _result.course_id
                this.getLevel(_result)
            } else {
                this.setHotCourseList(_result.records);
            }
        }

        private async getLevel (item: any) {
            let _this: any = this,
                _personData: any = _this.$store.getters['personal/personalData'],
                _subjectLevel: string = '';
            if(_personData && _personData.id) {
                let _res: any = await Caxios.get({ url: `/api/${_personData.id}/scores?student_id=${_personData.id}&courseid=${this.courseid}` });
                if(_res && Array.isArray(_res) && _res.length > 0) {
                    // _res.forEach((itm: any): void => {
                    //     if(itm.subject == item.subject) {
                    //         if(item.grade == itm.grade) {
                    //             _subjectLevel = `${itm.subject_name}-${itm.level_name}`
                    //         } else {
                    //             _subjectLevel = `${itm.subject_name}-暂无`
                    //         }
                    //     }
                    // })
                    // if(!_subjectLevel) {
                    //     _subjectLevel = `${item.subject_name}-暂无`
                    // }

                    _res.forEach((itm: any): void => {
                        if(itm.grade.indexOf(_personData.grade) >= 0) {
                            if(itm.subject == this.curItemSubject) {
                                _subjectLevel = `${itm.subject_name}-${itm.level_name}`
                            } else {
                                _subjectLevel = `${itm.subject_name}-暂无`
                            }
                        } else {
                            if(itm.level_name) {
                                _subjectLevel = `${itm.subject_name}-${itm.level_name}`
                            } else {
                                _subjectLevel = `${itm.subject_name}-暂无`
                            }
                        }
                    })
                    if(!_subjectLevel) {
                        _subjectLevel = `${item.subject_name}-暂无`
                    }
                } else {
                    _subjectLevel = 'None'
                }
            } else {
                _subjectLevel = 'None'
            }
            this.setSubjectLevel(_subjectLevel)
        }

        private async getSelectOptionsStable(id?: number) {
            let _result = await Caxios.get({ url: `/api/official/${id}/time?course_id=${id}&s_type=time`})
            this.setSelectOptionsStable(_result);
        }
    }
