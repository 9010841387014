
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
import { Caxios } from "./../../../../utils/axios";
import qs from "qs";
import SModal from "@/views/course/detail/components/modal.vue";
@Component({
  name: "MineCurriculum",
  computed: mapGetters("course", [
    "curriculumObj",
    "hotCourseList",
    "selectObj",
    "canSignupFlag",
  ]),
  methods: {
    ...mapMutations("common", ["setCommonComponentName"]),
    ...mapMutations('course', ['setUnSelectFlagStr'])
  },
  components: {
    SModal
  }
})
export default class MineCurriculum extends Vue {
  curriculumObj!: any;
  selectObj!: any;
  hotCourseList!: Array<any>;
  canSignupFlag!: boolean;
  setCommonComponentName!: any;
  setUnSelectFlagStr!: any
  @Prop() id!: any;
  public personInfo: any = localStorage.getItem('fangtian_personal_Data') || ''
  public testFlag = true
  public lodingflag = false
  private frequencyObj = {
    "一周一次":"Once a week",
    "一次": "Once",
    "上五休一": "A 1-day rest every 5 days' lesson",
    "上五休二": "A 2-day rest every 5 days' lesson",
    "无规律": "Irregular",
    "一天一次": " Once a day",
    "上七休一": "A 1-day rest every 7 days' lesson",
    "上六休一": "A 1-day rest every 6 days' lesson"
  }
  private async signUpEvent() {
    if (
      !this.canSignupFlag ||
      (!this.curriculumObj.course_id && this.id != 0)
    ) {
    } else {
      const _this: any = this,
        _store: any = _this.$store.getters["personal/personalData"];
      if (!_store.id) {
        this.setCommonComponentName("MineLogin");
      } else {
        if (this.id == 0) {
          if (!this.selectObj.grade) {
            _this.$message.warning("请选择年级");
            // this.setUnSelectFlagStr('grade')
            return;
          }
          if (!this.selectObj.area) {
            _this.$message.warning("请选择校区");
            // this.setUnSelectFlagStr('area')
            return;
          }
          if (this.selectObj.subject.length == 0) {
            _this.$message.warning("请选择学科");
            // this.setUnSelectFlagStr('subject')
            return;
          }
          if (!this.selectObj.restnum) {
            _this.$message.warning("请选择日期");
            // this.setUnSelectFlagStr('restnum')
            return;
          }
          this.setUnSelectFlagStr('')
          this.opentCreditwindow(_store, this.selectObj);
        } else {
          if (this.curriculumObj.course_id) {
            if (!this.selectObj.levels) {
              // _this.$message.warning("请选择班型");
              this.setUnSelectFlagStr('levels')
              return;
            }
            if (!this.selectObj.areas) {
              // _this.$message.warning("请选择校区");
              this.setUnSelectFlagStr('areas')
              return;
            }
            if (!this.selectObj.teachers) {
              // _this.$message.warning("请选择老师");
              this.setUnSelectFlagStr('teachers')
              return;
            }
            if (!this.selectObj.restnum) {
              // _this.$message.warning("请选择日期");
              this.setUnSelectFlagStr('restnum')
              return;
            }
            this.setUnSelectFlagStr('')
            this.getClassInfo(this.selectObj);
            // this.opentCreditwindow(_store, this.selectObj)
          }
        }
      }
    }
  }

  private signUpEventNewItem(item: any): void {
    sessionStorage.removeItem('fangtian_person_to_course_orderid');
    const _this: any = this,
      _route: any = _this.$route,
      _courseid: string = _route.query.courseid;
    if (_courseid != item.course_id) {
      _this.$router.replace(`/course/detail?courseid=${item.course_id}`);
    }
  }

  private async getClassInfo(item: any) {
    if (this.lodingflag) {
      this.$message.error('请勿重复点击')
      return
    }
    this.lodingflag = true
    const _res: any = await Caxios.post({
        url: `/api/class/getClassInfo?classId=${item.id}`,
      }),
      _this: any = this,
      _store: any = _this.$store.getters["personal/personalData"];
    if (_res && _res.id) {
      if (_res.sfSign == 1 || _res.sfSign == "1") {
        this.opentCreditwindow(_store, item);
      } else {
        this.enrollAjax(_store, item);
      }
    }
  }

  private opentCreditwindow(_store: any, selectObj: any) {
    const _this: any = this;
    _this
      .$confirm({
        title: "提示",
        subTitle:
          "报名后，请准时参加并签到，否则会扣除信用分，当信用分过低时，您将无法报名好问课程！",
        width: "400px",
        showClose: false,
        btns: {
          sure: "确认报名",
          cancel: "再想想",
        },
      })
      .then(async (msg: string) => {
        this.enrollAjax(_store, selectObj);
      })
      .catch(() => {});
  }

  private async enrollAjax(_store: any, selectObj: any) {
    let _data: any = {},
      _this: any = this,
      oldId = '',
      fporderid: any = sessionStorage.getItem('fangtian_person_to_course_orderid');
    if(fporderid) {
      oldId = fporderid
    }
    if (this.id == 0) {
      _data = {
        stdid: _store.id,
        classid: selectObj.onlyclassid,
        testsubject: selectObj.subject.join(","),
        type: "TEST",
        oldId: oldId,
      };
    } else {
      _data = {
        stdid: _store.id,
        classid: selectObj.id,
        type: "NORMAL",
        oldId: oldId,
      };
    }
    const _v: any = await Caxios.post({
      url: "/api/official/order/save",
      data: _data,
    });
    if (_v.msg) {
      this.lodingflag = false
      if (_v.msg == "您的信用分过低，无法报名当前课程！") {
        _this.$message.warning(_v.msg);
        this.openSeeCreditwin(_store);
      } else if (
        _v.msg == "当前课程需要学员的测试成绩，请先报名测试课程！" ||
        _v.msg == "您的测试级别已过期，请先报名入学册！"
      ) {
        _this
          .$confirm({
            title: "提示",
            subTitle: "当前课程需要学员的测试成绩，请先报名测试课程！",
            width: "400px",
            showClose: false,
            btns: {
              sure: "预约测试",
            },
            icon: 'error'
          })
          .then(async (msg: string) => {
            this.entranceTestEvent();
          })
          .catch(() => {});
      } else if (_v.msg == "已经报过该课程，未缴费") {
        _this
            .$confirm({
              title: "",
              subTitle: "Already enrolled in the course but have not paid the fee",
              width: "400px",
              showClose: false,
              icon: 'error',
              btns: {
                sure: "View order",
              },
            })
            .then(async (msg: string) => {
              this.clearSession();
              _this.$router.push({path: "/personal/index", query: {openCourse: '1', lessonstatus: this.selectObj.lessonstatus, price: this.selectObj.totalprice}});
            })
            .catch(() => {});
      } else if (_v.msg == "已经报过该课程，不能重复报名") {
        _this
          .$confirm({
            title: "",
            subTitle: "You have already signed up for this course. Please do not sign up again!",
            width: "400px",
            showClose: false,
            icon: 'error',
            btns: {
              sure: "View order",
            },
          })
          .then(async (msg: string) => {
            this.clearSession();
            _this.$router.push({path: "/personal/index", query: {openCourse: '1', lessonstatus: this.selectObj.lessonstatus, price: this.selectObj.totalprice}});
          })
          .catch(() => {});
      }else if (_v.msg == '1天内不能重复报名测试班！') {
        _this.$message.warning('You cannot sign up for the test class again within 1 day!');
      }else if(_v.msg == '请先报名线下体验！') {
        _this.$message.warning('Please register for the test for admission')
      }else if (_v.msg == '当前时段的班级，名额已满！') {
       _this.$message.warning('Classes in the current slot are full!')
      }else if (_v.msg == '无法报名，所选班级所有课次已下课') {
       _this.$message.warning('Classes in the current slot are end!')
      }
      else {
        _this.$message.warning(_v.msg);
      }
    } else {
      _this.lodingflag = false
      if (!this.selectObj.totalprice || this.selectObj.totalprice == '0') {
        this.$message.success('Congratulations on your successful registration!')
        this.$router.push({path: "/personal/index", query: {openCourse: '1', lessonstatus: this.selectObj.lessonstatus, price: this.selectObj.totalprice}});
        return
      }
      _this.$refs.modal.open()
      // _this.$message.success("Congratulations on your successful registration!");
      // sessionStorage.removeItem('fangtian_person_to_course_orderid')
      // this.clearSession();
      // _this.$router.push({path: "/personal/index", query: { openCourse: true }});
    }
  }
  private handleOk(): void {
    const _this = this
    // this.$message.success("报名成功");
    sessionStorage.removeItem('fangtian_person_to_course_orderid')
    this.clearSession();
    _this.$router.push({path: "/personal/index", query: {openCourse: '1', lessonstatus: this.selectObj.lessonstatus, price: this.selectObj.totalprice}});
  }
  private openSeeCreditwin(_store: any): void {
    const _this: any = this;
    _this
      .$confirm({
        title: "提示",
        subTitle: "当前信用分过低，无法报名该课程",
        width: "400px",
        showClose: false,
        btns: {
          sure: "查看信用分明细",
          cancel: "知道了!",
        },
      })
      .then(async (msg: string) => {
        const _res: any = await Caxios.get({
          url: `/api/getStdCreditRecord?stdid=${_store.id}`,
        });
        const _tempArr: Array<string> = [];
        if (_res.length > 0) {
          _res.forEach((item: any, index: number): void => {
            const _date: string = item.createtime
              .split(".")[0]
              .split("T")
              .join(" ");
            _tempArr[
              index
            ] = `<span style="width: 146px; display: inline-block;">${_date}</span>原因：${item.reason}`;
          });
          _this
            .$confirm({
              title: "积分明细",
              lists: _tempArr,
              width: "600px",
              showClose: false,
              btns: {
                sure: "关闭",
              },
            })
            .then(() => {})
            .catch(() => {});
        } else {
          _this.$message.warning("积分明细查询失败！");
        }
      })
      .catch(() => {});
  }
  private entranceTestEvent(): void {
    sessionStorage.removeItem('fangtian_person_to_course_orderid');
    const _this: any = this;
    _this.$router.replace("/course/detail?courseid=0");
  }
  private clearSession(): void {
    if (sessionStorage.getItem("fangtian_person_to_course")) {
      sessionStorage.removeItem("fangtian_person_to_course");
    }
  }
  // 判断是否已有入学测成绩
  private async getTestScore(): Promise<any> {
    const query = this.$route.query
    const data: any = { stdid: '', courseid: '' }
    if (query) {
      data.courseid = query.courseid
    }
    const userInfo = localStorage.getItem('fangtian_personal_Data')
    if (userInfo) {
      data.stdid = JSON.parse(userInfo).id
    }
    const res: any = await Caxios.get({url: `/api/student/score/validStudentTestScore?${qs.stringify(data)}`})
    if (res?.length <= 0) {
      this.testFlag = true
    }else {
      this.testFlag = false
    }
  }
  mounted() {
    this.getTestScore()
    if (this.personInfo) {
        this.personInfo = JSON.parse(this.personInfo)
    }
  }
  beforeDestroy() {
    this.clearSession();
  }
}
