
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapActions } from "vuex";
import CommonSelect from "./../../../../components/commonSelect/index.vue";

@Component({
  name: "CourseDetail",
  components: { CommonSelect },
  computed: {
    ...mapGetters("course", [
      "selectOptionsStable",
      "teachersList",
      "curriculumObj"
    ])
  },
  methods: {
    ...mapMutations("course", [
      "setSelectObj",
      "setCanSignupFlag",
      "setDelSelectAttr"
    ]),
    ...mapActions("course", ["setTeachersList"])
  }
})
export default class CourseDetail extends Vue {
  selectOptionsStable!: any;
  setSelectObj!: any;
  curriculumObj!: any;
  setCanSignupFlag!: any;
  setTeachersList!: any;
  setDelSelectAttr!: any;

  // 选择的内容
  private selectObj: any = {
    levels: "",
    areas: "",
    teachers: "",
    restnum: ""
  };

  private commonSelectData: any = {};

  @Watch("selectOptionsStable", { immediate: true, deep: true })
  selectOptionsStableWatch(newVal: any): void {
    this.commonSelectData = {};
    if (
      newVal &&
      newVal.items &&
      Array.isArray(newVal.items) &&
      newVal.items.length > 0
    ) {
      this.commonSelectData = { ...newVal };
      if (
        newVal.levels &&
        newVal.levels.length > 0 &&
        newVal.areas &&
        newVal.areas.length > 0 &&
        newVal.teachers &&
        newVal.teachers.length > 0 &&
        newVal.items &&
        newVal.items.length > 0
      ) {
        this.setCanSignupFlag(true);
      } else {
        this.setCanSignupFlag(false);
      }
    }
  }
  // 清除所有
  public delAllEvent() {
    const keys = Object.keys(this.selectObj)
    keys.forEach((key: string) => {
       this.selectObj[key] = ''
       const th = this
       setTimeout(() => {
        th.setDelSelectAttr(key);
       }, 10)
    })
  }

  // 表格中的元素
  private commonselectCb(item: any): void {
    this.setSelectObj({ ...item, ...this.selectObj });
  }
  // 选择班型、校区、老师
  private commonselectLat(item: any): void {
    this.selectObj = { ...item };
    this.setSelectObj({ ...item, ...this.selectObj });
  }

  // 删除选中的内容、选项
  private deleteSelectObj(attr: string): void {
    this.selectObj[attr] = "";
    this.setDelSelectAttr(attr);
  }
}
